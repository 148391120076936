<template>
  <div>

    <!-- filter -->
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 col-12">
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('days')" v-bind:class="{ 'btn-info': btnActive.days, 'btn-outline-info': btnActive.days == false }">Hari Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('month')" v-bind:class="{ 'btn-info': btnActive.month, 'btn-outline-info': btnActive.month == false }">Bulan Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('lastMonth')" v-bind:class="{ 'btn-info': btnActive.lastMonth, 'btn-outline-info': btnActive.lastMonth == false }">Bulan Kemarin</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" @click="btnActiveOnClick('year')" v-bind:class="{ 'btn-info': btnActive.year, 'btn-outline-info': btnActive.year == false }">Tahun Ini</button>
        <button class="btn mx-1 btn-timeseries" style="padding-left : 5px; padding-right : 5px; padding-top : 7px; padding-bottom : 7px;" v-bind:class="{ 'btn-info': btnActive.period, 'btn-outline-info': btnActive.period == false }" @click="btnPeriodeOnClick">Periode</button>
      </div>

      <div class="col-md-2">
        <treeselect
          v-model="dummy"
          :multiple="false"
          :options="services"
          placeholder="Filter Berdasar Layanan"
          @select="filterByService" />
      </div>

      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.doctor_name"
            placeholder="Cari Nama Dokter"
            @keyup="filterByDoctor"></b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-1">
        <b-form-select
          id="input-period"
          v-model="number"
          :options="formats"
          @change="perPageNumber"
          placeholder="Per Halaman (Def. 20)"></b-form-select>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mt-5 ml-2">
      <div class="col-md-6" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            <template #prepend>
              <b-button squared @click="resetFilterPeriode" variant="danger">Reset</b-button>
              <b-button squared @click="filterByPeriode" variant="success">Cari</b-button>
            </template>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <template #prepend>
                  <b-button squared @click="resetFilterPeriode" variant="danger">Reset</b-button>
                  <b-button squared @click="filterByPeriode" variant="success">Cari</b-button>
                </template>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>

      <div class="col-6" v-if="onShowPeriod == false"></div>

      <div class="col-4 d-flex justify-content-end">
        <button
          class="btn btn-primary mr-1"
          @click="btnExportOnClick">
          Ekspor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>
    </div>

    <!-- table -->
    <div class="row justify-content-between align-items-center mt-6">
      <div class="col-md-4">
        <span
          style="width: 150px; color: #a12260 !important"
          class="font-weight-bold">{{ recordData }} Baris Data</span>
      </div>

      <div class="col-md-6">
        <span
          style="width: 150px; color:#a12260 !important;"
          class="font-weight-bold">Total :</span>
        <span
          class="font-weight-bold"
          style="color:#a12260 !important;">Rp {{ summary.toLocaleString('id-ID') }}</span>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      class="mt-3"
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'">
      <template #cell(created_at)="data">
        {{ new Date(Date.parse(data.item.created_at)).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }) }}
      </template>
      <template #cell(commision)="data">
        {{ parseInt(data.item.commision).toLocaleString('id-ID') }}
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Komisi Dokter Klinik Hayandra'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf">

      <section slot="pdf-content">
        <template>
          <div id="print">
            <div class="d-flex justify-content-center mt-3">
              <h5>Laporan Komisi Dokter</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-6">
              <span
                style="width: 150px; color:#a12260 !important;"
                class="font-weight-bold">Total :</span>
              <span
                class="font-weight-bold"
                style="color:#a12260 !important;">Rp {{ summary.toLocaleString('id-ID') }}</span>
            </div>
            <b-table
              striped
              hover
              responsive
              class="mt-3"
              :items="items"
              :fields="fieldPrint"
              :style="'white-space: nowrap'">
              <template #cell(created_at)="data">
                <span class="text-sizing">
                  {{ new Date(Date.parse(data.item.created_at)).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }) }}

                </span>
              </template>
              <template #cell(commision)="data">
                <span class="text-sizing">
                  {{ parseInt(data.item.commision).toLocaleString('id-ID') }}

                </span>
              </template>
            </b-table>
          </div>
        </template>
      </section>
    </vue-html2pdf>

    <ModalReport
      :reportType="'doctor-commision-report'"
      :reportName="'Komisi Dokter'"
      :dataModal="dataModal"
      :excel="excelData" />

  </div>
</template>

<script>

import XLSX from "xlsx"
import VueHtml2pdf from "vue-html2pdf"
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { debounce } from '@/core/modules/Helper.js'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import ModalReport from "@/component/general/ModalReport.vue"
import moment from 'moment';


export default {

  components: {
    Treeselect,
    VueHtml2pdf,
    XLSX,
    ModalReport,
  },

  data() {
    return {
      // filter
      filter: {
        doctor_name: '',
        start_date: '',
        end_date: '',
        ref_id: '',
        ref_table: ''
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // per Page
      number: 20,
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData: 0,
      // table fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "service_name",
          label: "Layanan / Lab",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "commision",
          label: "Komisi",
          sortable: true,
        },
      ],
      fieldPrint: [
        {
          key: "number",
          label: "#",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "service_name",
          label: "Layanan / Lab",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "commision",
          label: "Komisi",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
      ],
      // table items
      items: [],
      excelData: [],
      dataModal: [],
      // Select Options
      services: [],
      // Other
      summary: 0,
      dummy: '',
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    }
  },

  methods: {
    perPageNumber() {
      this.perPage = this.number
      this.pagination()
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async getServiceOptions() {
      let response = await module.setPaymentServiceTreeSelect('payment-services')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.services = response.data
        this.services.unshift({ label: 'Filter Berdasar Layanan', id: '', isDisabled: true })
      }
    },

    async pagination() {
      let filterParams = `&doctor_name=${this.filter.doctor_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&ref_id=${this.filter.ref_id}&ref_table=${this.filter.ref_table}`
      let response = await module.paginate('doctor-commision-reports', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      // let responseExcel = await module.get('doctor-commision-excel-reports', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      // let responseReport = await module.get('doctor-commision-no-pagination-reports', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      this.recordData = pagination.total;

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      // this.excelData = responseExcel
      // this.dataModal = responseReport
      this.items = response.data

      this.summary = 0
      this.items.forEach(value => {
        this.summary += parseInt(value.commision)
      })
    },

    async btnExportOnClick() {
      let filterParams = `&doctor_name=${this.filter.doctor_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&ref_id=${this.filter.ref_id}&ref_table=${this.filter.ref_table}`

      let responseExcel = await module.get('doctor-commision-excel-reports', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responseReport = await module.get('doctor-commision-no-pagination-reports', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)

      this.excelData = responseExcel
      this.dataModal = responseReport

      if (responseReport) {
        this.$bvModal.show('modal-report')
      }
    },

    resetFilter() {
      this.filter.start_date = ''
      this.filter.end_date = ''
      this.filter.ref_id = ''
    },

    filterByDoctor() {
      debounce(() => {
        this.filter.start_date = ''
        this.filter.end_date = ''
        if (this.filter.doctor_name) {
          this.btnActiveOnClick(this.lastPeriodType)
        }
        this.pagination()
      }, 500)
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter)
      this.btnActiveOnClick(this.lastPeriodType)
      this.dummy = ''
      this.pagination()
    },

    filterByPeriode() {
      this.pagination()
    },

    async filterByService(evt) {
      await evt
      this.filter.ref_id = evt.realValue
      this.filter.ref_table = evt.refTable
      this.pagination()
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {

    },

    hasStartedGeneration() {

    },

    hasGenerated($event) {

    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Komisi Dokter.xlsx");
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      } else {
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type) {
      if (type == 'days') {
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "days"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'month') {
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "month"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'lastMonth') {
        // set time
        this.filter.start_date = moment().subtract(1, 'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')


        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "lastMonth"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'year') {
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        this.lastPeriodType = "year"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'period') {
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },

  },

  watch: {
    dummy: function (newVal) {
      if (typeof newVal === 'undefined') {
        this.filter.ref_id = ''
        this.filter.ref_table = ''
        this.dummy = ''
        this.pagination()
      }
    }
  },

  mounted() {
    this.pagination()
    this.getServiceOptions()
  }

}
</script>

<style>
.text-sizing {
  font-size: 10px;
}
</style>